import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { Link } from "gatsby";

const Alert = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Alert Area --> */}
      <div className="alert-section py-9 bg-blackish-blue dark-mode-texts ">
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="6" md="6" sm="9" xs="10">
              <div className="alert-content d-flex flex-column flex-lg-row align-items-center justify-content-lg-center text-center">

                <span className="alert-text gr-text-9 gr-text-color">
                  Concerned that you might have ADHD or need an ADHD Test?{" "}
{/*                   <a href={`https://akkadianhealth-2.snapforms.com.au/form/screening`} target="_blank" rel="noreferrer">
                  <Button variant="outline" className="gr-hover-y w-80 mx-9" style={{borderWidth: '3px', borderColor: '#ffad00', padding: '0.6rem 0.6rem', fontWeight: 'bold', color: '#darkgrey'}}>Take Our Quick Quiz</Button>
                  </a> */}

                </span>
              </div>
            </Col>

            <Col xl="6" lg="6" md="6" sm="9" xs="10">
              <div className="alert-content d-flex flex-column flex-lg-row text-center">

                <span className="alert-text gr-text-9 gr-text-color">

{/*                   <a href={`https://akkadianhealth-2.snapforms.com.au/form/screening`} target="_blank" rel="noreferrer">
                  <Button variant="outline" className="gr-hover-y w-80 mx-9" style={{borderWidth: '3px', borderColor: '#ffad00', padding: '0.6rem 0.6rem', fontWeight: 'bold', color: '#darkgrey'}}>Take Our Quick Quiz</Button>
                  </a> */}

                  <Link to={`services/adhd-testing`} className="gr-hover-y w-80">Learn More...</Link>

                </span>
              </div>
            </Col>


          </Row>
        </Container>
      </div>
    </>
  );
};

export default Alert;
