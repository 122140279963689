import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {Link} from "gatsby";
import img5Star from "../../assets/image/l5/png/5-stars.png";
import img_T from "../../assets/image/akkadian/adhd_testimonial.png";
import TestimonialSlider from "./TestimonialSlider";

const Testimonial = ({ id }) => {
    return (
        <>
            {/* <!-- Testimonial Area --> */}
            <div id={id} className="testimonial-section pt-15 pt-lg-24 pb-lg-12 bg-default-1">
                <Container>
                    <div className="section-title text-center mb-7 mb-lg-11">
{/* 					 <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
						Testimonials
					</h4> */}
                        <h2 className="title gr-text-4 mb-9">Don't just take our word for it</h2>
						<p className="gr-text-9 mb-9">We've now seen over 6000 people just like you right across Australia with life-changing outcomes through accessible high-quality mental health services.</p>
						<hr />
                    </div>
					
					
          <Row className="align-items-center justify-content-around">

            <Col lg="3" md="6" sm="9" >
              <div className="testimonial-widget-1 text-center mb-13 mb-lg-0">
                <img src={img_T} alt="" className="img-fluid w-100" />
              </div>
            </Col>
            
            <Col lg="9" md="6" sm="9" >
              <TestimonialSlider />
            </Col>
          </Row>					



                </Container>
            </div>

            <div className="section-title text-center mb-9 mb-lg-16">
				<hr />
				
								<p className="gr-text-10 px-lg-4">
								Please review our <Link to={`/faq`} className={`gr-text-9`}> FAQ </Link>
								 for more detailed information about what to expect.</p>
								<Link to="faq">
								<Button variant="warning" className="gr-hover-y w-80 w-sm-auto">
								FAQ</Button>
								</Link>				
				</div>            
        </>
    );
};

export default Testimonial;
