import React from "react";
import img5Star from "../../assets/image/l5/png/5-stars.png";

const TestimonialItem = ({ rating, name, job, text }) => (
  <div className="testimonial-widget-1 text-center mb-13 mb-lg-0">
    <img className="rating mb-11" src={img5Star} alt="" />
    <div className="review mb-12">
      <h3 className="px-22 gr-text-6 mb-6 mb-lg-8">{text}</h3>
      <p className="gr-text-8 mb-lg-8"></p>
      <p className="name gr-text-color gr-text-9 font-weight-bold mb-0">{name}</p>
      <p className="job gr-text-11">{job}</p>
    </div>
  </div>
  );

  export default TestimonialItem;