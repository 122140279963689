import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "gatsby";
import imgHero from "../../assets/image/akkadian/akkadian-health2.jpg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { backgroundColor } from "styled-system";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="akkadian-hero bg-image bg-gradient bg-parallax-image"
        css={`
          background-image: url(${imgHero});
        `}
      >
        <div className="container-fluid">
          <div className="gr-abs-bl hero-out-box"></div>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col
                md="12"
                lg="12"
                xl="12"
              >
                <div className="hero-box pr-35 pt-10 pb-10">
                  <div className="hero-content section-title z-index-99 dark-mode-texts">
                    <h1 className="gr-text-3 font-weight-bold mb-4">
                      Access mental health specialists – anywhere, anytime
                  </h1>
                    <p className="gr-text-10 gr-color-white-opacity-9 pr-sm-7 pr-md-12">
                      We connect you to high quality mental health professionals via secure telehealth across Australia.
                  </p>
                    <div className="hero-btns mt-4">
					
                      <AnchorLink to={`#process`}>
                        <Button 
                            style={ 
                              { backgroundColor: "rgb(177 243 255)",  
                                borderColor: "rgb(177 243 255)"}}
                            className="gr-hover-y mb-5 mb-sm-0 w-100 w-sm-auto mr-4 text-dark">
                          How it works
                        </Button>
                      </AnchorLink>							  

                      <AnchorLink to={"/services/adhd-testing"}>
                        <Button variant="warning" className="gr-hover-y mb-5 mb-sm-0 w-100 w-sm-auto mr-4" style={{backgroundColor: '#ffad00', fontWeight: '400'}}>

                          ADHD Testing
						            </Button>
                      </AnchorLink>

					  
					            <AnchorLink to={`#contact`}>
                        <Button variant="outline-dark" className="gr-hover-y mb-5 mb-sm-0 w-100 w-sm-auto mr-4">
                          Contact us
                        </Button>
                      </AnchorLink>


			  
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Hero;
