import React from "react";
import img5Star from "../../assets/image/l5/png/5-stars.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialItem from "./TestimonialItem";

const TestimonialSlider = ({ id }) => {

  const responsive = {
    desktop: {
        breakpoint: { max: 3900, min: 1200 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1200, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
    };

  return (
    <Carousel responsive={responsive} 
    indicators={true} controls={true} interval={null} fade={false} 
    autoPlay={true}
    showDots={true}
    infinite={true}
    keyBoardControl={true}
    ssr={false}
    partialVisible={false}
    autoPlaySpeed={6000}    
    containerClass="carousel-container"
    itemClass="carousel-item-padding-80-px"
    dotListClass="custom-dot-list-style"
    className="mt-11 mt-lg-0">
      {[
        {
          rating: img5Star,
          name: "C.C.",
          job: "SA",
          text: "They're making these services more accessible and affordable..."
        },
        {
          rating: img5Star,
          name: "C.S.",
          job: "SA",
          text: "Akkadian Health deliver fast, convenient, and effective services."
        },
        {
          rating: img5Star,
          name: "S.L.",
          job: "SA",
          text: "It's been a long journey and I'm eager to continue."
        }
      ].map((item, index) => (
        <div key={index}>
          <TestimonialItem
            rating={item.rating}
            name={item.name}
            job={item.job}
            text={item.text}
          />
        </div>
      ))}

    </Carousel>
  );
}

export default TestimonialSlider;