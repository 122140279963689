import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Process  = ({ id }) => {
  return (
    <>
      {/* <!-- Process Area --> */}
      <div  id={id} className="how-section bg-default-4 pt-12 pb-5 py-lg-15">
        <Container fluid className="mt-md-10 pt-md-10">
          <Row className="justify-content-center">
            <Col xl="7" lg="7" md="10">
              <div className="section-title text-center mb-11 mb-lg-17">
{/* 			    <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                  Our process
                </h4> */}
                <h2 className="title gr-text-4 mb-7">How does it work?</h2>

{/* 				<p className="px-lg-8 gr-text-10">
                  First you'll need a <strong><Link to={`/for-gps`} className={`gr-text-9`}>referral from your GP</Link></strong>.
				</p> */}
				
				<p className="px-lg-2 gr-text-10">
                  A simple <strong>Online Book & Connect</strong> process offers quick access to our psychiatrists and other mental health professionals.{" "}
                </p>
				
              </div>
            </Col>
          </Row>
          <Row className="gr-timeline-wrapper align-items-start mb-11 mb-lg-17 mx-md-12 mx-lg-18 mx-xl-30">
            <Col md={6} lg={3}
              className="px-6 px-md-9"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              <div className="count circle-xl bg-orange gr-text-8 ml-3 ml-lg-9 mb-lg-12 mb-6 text-white">
                <span>Step 1</span>
              </div>
              <div className="content py-6" style={{textAlign:"left"}}>
                <h3 className="title gr-text-7 mb-6" style={{color:'#ffad00'}}>Step 1.  
                  <span style={{fontWeight:'bold', color:'black'}}> Speak to your GP...</span></h3>
                <p className="gr-text-9">
                  The first step to access Akkadian Health services, is to discuss your health concerns with your regular GP. For ADHD concerns, you need to seek specialised ADHD testing and assessment from a registered psychiatrist. 
                  <br/> <br/> Ask your GP <strong>to refer you to Akkadian Health*</strong>.{" "}
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}
              className="px-6 px-md-9"
              data-aos="zoom-in"
              data-aos-duration="900"
              data-aos-delay="500"
            >
              <div className="count circle-xl bg-akk-blue gr-text-8 ml-3 ml-lg-9 mb-lg-12 mb-6 text-white">
                <span>Step 2</span>
              </div>
              <div className="content py-6" style={{textAlign:"left"}}>
                <h3 className="title gr-text-7 mb-6" style={{color:'#0e97a7'}}>Step 2.  
                  <span style={{fontWeight:'bold', color:'black'}}> Submit the referral to us...</span></h3>
                <p className="gr-text-9">
                  Ensure your GP has included all of the necessary information to fast track the referral process. 
                  <br/><br/>Then, <strong>simply send the referral to us**</strong>.{" "}
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}
              className="px-6 px-md-9"
              data-aos="zoom-in"
              data-aos-duration="900"
              data-aos-delay="1000"
            >
              <div className="count circle-xl bg-akk-dark-green gr-text-8 ml-3 ml-lg-9 mb-lg-12 mb-6 text-white">
                <span>Step 3</span>
              </div>
              <div className="content py-6" style={{textAlign:"left"}}>
                <h3 className="title gr-text-7 mb-6" style={{color:'#9cac00'}}>Step 3.  
                  <span style={{fontWeight:'bold', color:'black'}}> Your referral is received...</span></h3>
                <p className="gr-text-9">
                  When we receive your referral, we'll send you an SMS with detailed instructions to <strong>book and pay for your appointment(s) online</strong>. 
                  <br/><br/> We now offer <strong>flexible payment options</strong>. {" "}
				        </p>
              </div>
            </Col>
            <Col md={6} lg={3}
              className="px-6 px-md-9"
              data-aos="zoom-in"
              data-aos-duration="900"
              data-aos-delay="1500"
            >
              <div className="count circle-xl bg-akk-dark-blue gr-text-8 ml-3 ml-lg-9 mb-lg-12 mb-6 text-white">
                <span>Step 4</span>
              </div>
              <div className="content py-6" style={{textAlign:"left"}}>
                <h3 className="title gr-text-7 mb-6" style={{color:'#0086d9'}}>Step 4.  
                  <span style={{fontWeight:'bold', color:'black'}}> Your appointment...</span></h3>
                <p className="gr-text-9">
                  For ADHD Assessments, you will have <strong>two telehealth appointments</strong>.  
                  Your first session will be with a mental health nurse, followed by a session with a highly experienced 
                  psychiatrist for the final diagnosis and treatment plan for you and your GP to manage going forward.
                  <br/><br/> Our appointments are conducted conveniently over <strong>telehealth (video) sessions</strong>.{" "}
				        </p>
              </div>
            </Col>
			
          </Row>
		  
          <Row className="pt-12 mx-md-12 mx-lg-22 mx-xl-34">
                <Col md={6}>
                <p className="gr-text-12 mx-6 mb-3">
                  * Take our ADHD quick symptom quiz to receive your GP referral guide (or simply download the guide) so that you and your GP have everything you need to fast track your assessment.{' '}
                </p> 

                <div className="px-4 text-center mb-12 mb-lg-18">
                <a href={`https://akkadianhealth-2.snapforms.com.au/form/screening`} target="_blank" rel="noreferrer">
                    <Button variant="warning" className="gr-hover-y w-80 mt-6" style={{backgroundColor: '#ffad00', padding: '0.6rem 0.6rem', fontSize: '1.3rem', fontWeight: 'bold'}}>Take Quiz</Button>
                  </a>
                  <AnchorLink to={`/services/adhd-testing/#guide`}>
                    <Button variant="outline" className="gr-hover-y w-80 mx-9 mt-6" style={{borderWidth: '4px', borderColor: '#ffad00', padding: '0.6rem 0.6rem', fontSize: '1.1rem', fontWeight: 'bold', color: '#darkgrey'}}>Download Referral Guide</Button>
                  </AnchorLink>
                </div>  
                </Col>          

                <Col md={6}>
                  <p className="gr-text-12 mx-6 mb-3">
                  ** If you have an electronic copy (or image) of a valid GP referral letter, please send it to us via{' '}
                  <a style={{ color: 'blue' }} href="mailto:reception@akkadianhealth.com">
                    reception@akkadianhealth.com
                  </a>{' '}
                  or ask your GP to send us your referral directly.
                </p> 
                </Col>

              </Row>

        </Container>
      </div>

    </>
  );
};

export default Process;
