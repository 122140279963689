import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import icon1 from "../../assets/image/svg/l5-feature-icon1.svg";
import icon2 from "../../assets/image/svg/l5-feature-icon2.svg";
import icon3 from "../../assets/image/svg/l5-feature-icon3.svg";
import icon4 from "../../assets/image/svg/l5-feature-icon4.svg";

const Feature = ({ id }) => {
  return (	
  <>
    {/* <!-- Feature section --> */}
    <div id={id} className="feature-section pt-14 pt-lg-25 pb-7 pb-lg-11 bg-default-2">
      <Container>
        <Row className="justify-content-center">
          <Col xl="6" lg="7" md="8">
            <div className="section-title text-center mb-8 mb-lg-14">
{/*               <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                Why us
              </h4> */}
              <h2 className="title gr-text-4">
                Why choose us?
              </h2>
            </div>
          </Col>
        </Row>
		
		<Row className="justify-content-center align-items-center">
			<Col md="12">
				<div className="section-title text-center mb-4 mb-lg-7 pb-7">
					<h4 className="gr-text-7 mb-4">We see you, we hear you... and we're here to help!</h4>
					<p className="gr-text-10 px-lg-8 mb-7">
						At Akkadian Health we believe that high quality mental healthcare should be equally available to everyone – anywhere and anytime. We've built a team of dedicated mental health clinicians, psychologists and RANZCP-registered psychiatrists in Australia. 
						Speak to your GP to arrange a referral to our services.
					</p>                            
				</div>
			</Col>
		</Row>			
		
		
        <Row className="justify-content-center">
          <Col md="10">
            <Row className="align-items-center justify-content-center">
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-blue-opacity-1">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="widget-text">
                    <h3 className="title gr-text-7 mb-6">
                      Clinical Experience
                    </h3>
                    <p className="gr-text-9 mb-0 pr-11">
					  Our RANZCP-registered psychiatrists in Australia are there for you every step of the way.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-red-opacity-1">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="widget-text">
                    <h3 className="title gr-text-7 mb-6">Collaborative Care</h3>
                    <p className="gr-text-9 mb-0 pr-11">
                      Work with our clinical team to discuss and action your treatment plans.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-green-opacity-1">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="widget-text">
                    <h3 className="title gr-text-7 mb-6">
                      Technology
                    </h3>
                    <p className="gr-text-9 mb-0 pr-11">
                      We take an innovative, digital-first approach to health care.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="800"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-blackish-blue-opacity-1">
                    <img src={icon4} alt="" />
                  </div>
                  <div className="widget-text">
                    <h3 className="title gr-text-7 mb-6">
                      Timely Appointments
                    </h3>
                    <p className="gr-text-9 mb-0 pr-11">
                      Access our dedicated health professionals from the comfort of your own home.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  </>
  );  
};

export default Feature;
